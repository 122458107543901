import { PURGE } from "redux-persist";

const initialState = {
  assignments: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case "FETCH_PATIENT_ASSIGNMENTS":
      return {
        ...state,
        assignments: [...payload],
      };
    case "ADD_PATIENT_ASSIGNMENT":
      return {
        ...state,
        assignments: [...state.assignments, payload],
      };
    case "DELETE_PATIENT_ASSIGNMENT":
      return {
        ...state,
        assignments: state.assignments.filter(
          (assignment) => assignment.uuid !== payload.uuid
        ),
      };
    case "UPDATE_NOTIFICATION":
      return {
        ...state,
        assignments: state.assignments.map((assignment) =>
          assignment.uuid === payload.entityId
            ? {
                ...assignment,
                notification: {
                  ...payload,
                },
              }
            : assignment
        ),
      };
    case "UPDATE_PATIENT_ASSIGNMENT":
      const newAssignments = state.assignments.filter(
        (item) => item.uuid !== payload.uuid
      );
      return {
        ...state,
        assignments: [...newAssignments, payload],
      };
    case PURGE:
      return initialState;
    default:
      return state;
  }
};
