import "./Avatar.styles.scss";
import React from "react";
import { getInitials } from "utils/helpers";

interface AvatarProps {
  firstName?: string | null;
  lastName?: string | null;
  fullName?: string;
}

const Avatar = ({ firstName, lastName, fullName }: AvatarProps) => {
  let initials;

  if (fullName) {
    initials = getInitials(fullName);
  }

  if (firstName && lastName) {
    initials = `${firstName?.substring(0, 1)} ${lastName?.substring(0, 1)}`;
  }

  return <div className="avatar-wrapper">{initials}</div>;
};

export default Avatar;
