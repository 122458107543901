import { useDispatch } from "react-redux";
import ReactGA from "react-ga4";
import { deleteActionPlanAssignmentAction } from "store/actions/actionPlans";
import { toast } from "react-toastify";
import { useLogout, useCurrentUser, useFetchAssignments } from "utils/hooks";
import { deletePatientAssignmentAction } from "store/actions/assignments";

const useDeleteAssignment = () => {
  const dispatch = useDispatch();
  const { logOut } = useLogout();
  const { fetchAllAssignments } = useFetchAssignments();
  const { user } = useCurrentUser();

  const deleteAssignment = (args, isForPatient = false) => {
    if (!args) {
      return null;
    }

    const mutation = isForPatient
      ? `mutation {
      deleteAssignmentForPatient(assignment: {
        uuid: "${args.uuid}"
      })
    }`
      : `mutation {
      deleteAssignment(assignment: {
        actionPlanId: "${args.actionPlanId}",
        uuid: "${args.uuid}"
      })
    }`;
    return new Promise(() => {
      setTimeout(() => {
        fetch(`${process.env.REACT_APP_API_URL}`, {
          method: "POST",
          credentials: "include",
          body: JSON.stringify({
            query: `
            ${mutation}
          `,
          }),
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
          .then(async (res) => {
            if (res.status !== 200 && res.status !== 201) {
              const error = await res.json();
              if (error.errors[0].message.includes("BRKN_6001")) {
                toast.warn(`For security purposes please log in again.`);
                logOut();
              } else {
                toast.error(`Hmm, something went wrong.`);
                throw new Error("Failed!");
              }
            }
            return res.json();
          })
          .then((resData) => {
            if (resData.errors) {
              toast.error(
                `Hmm, something went wrong. ${resData.errors[0].message}`
              );
            } else {
              return dispatch(
                isForPatient
                  ? deletePatientAssignmentAction(
                      resData.data.deleteAssignmentForPatient[0]
                    )
                  : deleteActionPlanAssignmentAction(
                      resData.data.deleteAssignment[0]
                    )
              );
            }
          })
          .then((res) => {
            if (res) {
              fetchAllAssignments();
              ReactGA.event("assignment_deleted_success", {
                user_id: user?.id,
                actionPlan_id: args.actionPlanId,
                assignment_id: args.uuid,
                type: args.label,
              });
              toast(`Done! ${args.label} has been deleted`);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }, 100);
    });
  };

  return {
    deleteAssignment,
  };
};

export default useDeleteAssignment;
