import React, { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useUserProfile } from "utils/hooks";
import { Button, Input, Select, Toggle, Modal } from "components";
import { useTranslation } from "react-i18next";
import TurnOff2FASettingConfirmation from "./components/TurnOff2FASettingConfirmation";
import { User } from "types";

interface ProfileSettingsProps {
  user: User | null;
}

const ProfileSettings = ({ user }: ProfileSettingsProps) => {
  const { updateUserProfile, isProfileSubmitting } = useUserProfile();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const languageOptions = [
    { label: "English (US)", value: "en" },
    { label: "עברית", value: "he" },
  ];

  const { register, handleSubmit, control, errors } = useForm();

  const mappedCurrentLanguage = {
    value: user?.defaultLanguage,
    label: languageOptions.find((l) => l.value === user?.defaultLanguage)
      ?.label,
  };

  const { t } = useTranslation(["common"]);

  const [currentDigestSetting, setCurrentDigestSetting] = useState(
    user?.digestSubscription
  );

  const [current2FASetting, setCurrent2FASetting] = useState(
    user?.is2FAEnabled || false
  );
  const [is2FAChanged, setIs2FAChanged] = useState(false);

  const handleDigestSettingChange = () => {
    setCurrentDigestSetting(!currentDigestSetting);
  };

  const handle2FASettingChange = () => {
    if (current2FASetting) {
      setIsModalOpen(true);
    } else {
      setCurrent2FASetting(true);
      setIs2FAChanged(true);
    }
  };

  const handleTurnOff2FASetting = () => {
    setCurrent2FASetting(false);
    setIs2FAChanged(true);
    setIsModalOpen(false);
  };

  const onSubmit = (data: User) => {
    updateUserProfile({
      ...data,
      is2FAEnabled: is2FAChanged ? current2FASetting : false,
    });
  };

  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const watchedFirstName = useWatch({ control, name: "firstName" });
  const watchedLastName = useWatch({ control, name: "lastName" });
  const watchedLanguage = useWatch({ control, name: "defaultLanguage" }) as {
    label: string;
    value: string;
  };
  const watchedDigestSubscription = useWatch({
    control,
    name: "digestSubscription",
  });
  useEffect(() => {
    if (
      watchedFirstName !== user?.firstName ||
      watchedLastName !== user?.lastName ||
      watchedLanguage.value !== user?.defaultLanguage ||
      watchedDigestSubscription !== user?.digestSubscription ||
      current2FASetting !== user?.is2FAEnabled
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  }, [
    watchedFirstName,
    watchedLastName,
    watchedLanguage,
    watchedDigestSubscription,
    current2FASetting,
    user,
  ]);

  return (
    <section className="settings-section" id="profile-settings">
      <h2 className="section-title">{t("profile.profile_settings_label")}</h2>
      <hr className="divider" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="settings-section__content">
          <Input
            name="firstName"
            type="text"
            label={t("common_labels.first_name")}
            defaultValue={user?.firstName ?? undefined}
            placeholder="Peter"
            forwardRef={register({ required: true })}
            error={errors.firstName && t("profile.user_first_name_required")}
          />
          <Input
            name="lastName"
            type="text"
            label={t("common_labels.last_name")}
            defaultValue={user?.lastName ?? undefined}
            placeholder="Parker"
            forwardRef={register({ required: true })}
            error={errors.lastName && t("profile.user_last_name_required")}
          />
          <Select
            name="defaultLanguage"
            label={t("common_labels.default_language")}
            forwardRef={register()}
            options={languageOptions}
            forwardControl={control}
            defaultValue={mappedCurrentLanguage}
            onChange={() => console.log("language changed")}
          />
          <Input
            name="email"
            type="email"
            label={t("common_labels.email")}
            disabled
            defaultValue={user ? user.email : undefined}
            placeholder="name@email.com"
            hint={
              <span>
                {t("profile.change_email_address_prefix")}{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:support@reflectiveapp.com"
                >
                  {t("profile.change_email_address_label")}
                </a>
                .
              </span>
            }
          />
          {user?.type === "therapist" && (
            <>
              <h4 className="no-mt">{t("profile.digest_settings_label")}</h4>
              <Toggle
                name="digestSubscription"
                id="digestSubscription"
                checked={currentDigestSetting ? true : false}
                forwardRef={register()}
                onChange={handleDigestSettingChange}
                onText="Yes"
                offText="No"
                isSmall={false}
              />
              <p className="mt-2">
                <small>{t("profile.digest_settings_explainer")}</small>
              </p>
            </>
          )}
          <div style={{ display: "none" }}>
            <h4 className="no-mt">{t("profile.2fa_settings_label")}</h4>
            <Toggle
              name="is2FAEnabled"
              id="is2FAEnabled"
              checked={current2FASetting ? true : false}
              forwardRef={register()}
              onChange={handle2FASettingChange}
              onText="On"
              offText="Off"
              isSmall={false}
            />
            <p className="mt-2">
              <small>{t("profile.2fa_settings_explainer")}</small>
            </p>
          </div>
          <Button
            type="submit"
            name="profile_settings_button"
            value={t("common_labels.submit_changes")}
            isLoading={isProfileSubmitting}
            disabled={!hasChanges || isProfileSubmitting}
          />
        </div>
      </form>
      <hr className="divider" />
      <p>
        <small>{t("profile.reset_password_instructions_intro")}</small>
        <br />
        <small>{t("profile.reset_password_instructions_explainer")}</small>
      </p>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <TurnOff2FASettingConfirmation
          onTurnOff2FASetting={handleTurnOff2FASetting}
          onCancel={() => setIsModalOpen(false)}
        />
      </Modal>
    </section>
  );
};

export default ProfileSettings;
