import AssignmentFields from "pages/AppPages/TherapistPages/SinglePatient/components/PatientWorkbook/components/AssignmentDetails/AssignmentFields";
import React from "react";
import { useParams } from "react-router-dom";
import { useGetTemplates } from "utils/hooks";

const TemplatePreview = () => {
  const { templateId } = useParams();
  const { specificTemplate } = useGetTemplates({
    templateId: templateId,
  });

  if (!templateId || !specificTemplate) {
    return <p>No template found</p>;
  }

  return (
    <div style={{ padding: "1em" }}>
      <AssignmentFields
        assignment={specificTemplate?.content}
        fieldComponent={null}
      />
    </div>
  );
};

export default TemplatePreview;
