import React from "react";

interface HeadingFieldProps {
  label: string;
  inlineValue: string;
  showLabelForBuilder?: boolean;
}

const HeadingField = ({
  label,
  inlineValue,
  showLabelForBuilder,
}: HeadingFieldProps) => {
  return (
    <div>
      {showLabelForBuilder && <p>{label}</p>}
      <h3>{inlineValue}</h3>
      {!inlineValue || inlineValue === "" ? (
        <small>
          <i>Add content in field settings</i>
        </small>
      ) : undefined}
    </div>
  );
};

export default HeadingField;
