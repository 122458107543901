import Smile from "assets/icons/custom-assignments/face-smile.svg";
import Frown from "assets/icons/custom-assignments/face-frown.svg";
import Meh from "assets/icons/custom-assignments/face-meh.svg";
import GrinBeam from "assets/icons/custom-assignments/face-grin-beam.svg";
import GrinTears from "assets/icons/custom-assignments/face-grin-tears.svg";
import GrinHearts from "assets/icons/custom-assignments/face-grin-hearts.svg";
import Heart from "assets/icons/custom-assignments/heart.svg";
import Sun from "assets/icons/custom-assignments/sun.svg";
import Moon from "assets/icons/custom-assignments/moon.svg";
import Cloud from "assets/icons/custom-assignments/cloud.svg";
import Umbrella from "assets/icons/custom-assignments/umbrella.svg";
import Star from "assets/icons/custom-assignments/star.svg";
import Leaf from "assets/icons/custom-assignments/leaf.svg";
import Running from "assets/icons/custom-assignments/person-running.svg";
import Hiking from "assets/icons/custom-assignments/person-hiking.svg";
import Yoga from "assets/icons/custom-assignments/person-praying.svg";
import Stethoscope from "assets/icons/custom-assignments/stethoscope.svg";
import Brain from "assets/icons/custom-assignments/brain.svg";
import Comments from "assets/icons/custom-assignments/comments.svg";
import BookOpen from "assets/icons/custom-assignments/book-open.svg";
import Music from "assets/icons/custom-assignments/music.svg";
import Pen from "assets/icons/custom-assignments/pen.svg";
import PaintBrush from "assets/icons/custom-assignments/paintbrush.svg";
import Lightbulb from "assets/icons/custom-assignments/lightbulb.svg";
import PuzzlePiece from "assets/icons/custom-assignments/puzzle-piece.svg";
import Mountain from "assets/icons/custom-assignments/mountain.svg";
import HandsHelping from "assets/icons/custom-assignments/hands-holding-circle.svg";
import ThumbsUp from "assets/icons/custom-assignments/thumbs-up.svg";
import ThumbsDown from "assets/icons/custom-assignments/thumbs-down.svg";
import LifeRing from "assets/icons/custom-assignments/life-ring.svg";

import ThoughtRecord from "assets/icons/waveform-path.svg";
import MoodTracker from "assets/icons/cloud-rainbow.svg";
import JournalIcon from "assets/icons/book-spells.svg";
import SafetyPlanIcon from "assets/icons/lifebuoy.svg";
import GratitudeJournalIcon from "assets/icons/book-heart.svg";
import SocraticQuestionsIcon from "assets/icons/map-marker-question.svg";
import CognitiveRestructuringIcon from "assets/icons/brain.svg";
import CognitiveTriangleIcon from "assets/icons/recycle.svg";
import GoalJournalIcon from "assets/icons/atlas.svg";
import WorryJournalIcon from "assets/icons/temperature-high.svg";
import UpcomingSessionIcon from "assets/icons/calendar-circle-user.svg";
import PostSessionIcon from "assets/icons/memo-pad.svg";
import SensesExerciseIcon from "assets/icons/5.svg";
import ArrowDown from "assets/icons/angle-down.svg";

import DraftIcon from "assets/icons/pen-ruler.svg";

import Schedule from "assets/icons/workbook-buttons/schedule.svg";
import Configure from "assets/icons/workbook-buttons/configure.svg";
import Preview from "assets/icons/workbook-buttons/preview.svg";
import Remove from "assets/icons/workbook-buttons/remove.svg";
import ChangeIcon from "assets/icons/workbook-buttons/change-icon.svg";
import CancelArrow from "assets/icons/workbook-buttons/cancel-arrow.svg";
import HideIcon from "assets/icons/workbook-buttons/hide-icon.svg";

import ArrowBack from "assets/icons/arrow-back.svg";

import Bell from "assets/icons/bell.svg";

const iconMap = {
  Smile,
  Frown,
  Meh,
  GrinBeam,
  GrinTears,
  GrinHearts,
  Heart,
  Sun,
  Moon,
  Cloud,
  Umbrella,
  Star,
  Leaf,
  Running,
  Hiking,
  Yoga,
  Stethoscope,
  Brain,
  Comments,
  BookOpen,
  Music,
  Pen,
  PaintBrush,
  Lightbulb,
  PuzzlePiece,
  Mountain,
  HandsHelping,
  ThumbsUp,
  ThumbsDown,
  LifeRing,
  ThoughtRecord,
  MoodTracker,
  JournalIcon,
  SafetyPlanIcon,
  GratitudeJournalIcon,
  SocraticQuestionsIcon,
  CognitiveRestructuringIcon,
  CognitiveTriangleIcon,
  GoalJournalIcon,
  WorryJournalIcon,
  UpcomingSessionIcon,
  PostSessionIcon,
  SensesExerciseIcon,
  DraftIcon,
  ArrowDown,
  Schedule,
  Configure,
  Preview,
  Remove,
  ChangeIcon,
  CancelArrow,
  HideIcon,
  ArrowBack,
  Bell,
};

export default iconMap;
