import "./TextareaField.styles.scss";
import React from "react";
import PropTypes from "prop-types";

const TextareaField = ({
  label,
  error,
  forwardRef,
  hint,
  required,
  ...props
}) => {
  return (
    <div className="textarea-field">
      <label className="textarea-field__label">
        {label} {required === "false" || !required ? "(Optional)" : null}
      </label>
      <textarea
        rows="6"
        aria-label={label}
        className={`textarea-field__field ${error ? "error" : ""}`}
        ref={forwardRef}
        required={required === "false" ? false : true}
        {...props}
      />
      {error && (
        <p className="textarea-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="textarea-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

TextareaField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

TextareaField.defaultProps = {
  type: "text",
};

export default TextareaField;
