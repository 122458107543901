import "./SelectField.styles.scss";
import React from "react";
import { components } from "react-select";
import { Select, Icon } from "components";
import { THOUGHT_DISTORTIONS, NEGATIVE_EMOTIONS } from "utils/constants";
import QuoteLeft from "assets/icons/quote-left.svg";

const SelectField = ({
  choicesKey,
  options,
  defaultValue,
  forwardControl,
  type,
  ...props
}) => {
  let setOptions = null;

  const isThoughtDistortions =
    choicesKey === "THOUGHT_DISTORTIONS" ||
    type === "THOUGHT_DISTORTIONS_FIELD";

  switch (choicesKey) {
    case "THOUGHT_DISTORTIONS":
      setOptions = THOUGHT_DISTORTIONS;
      break;
    case "NEGATIVE_EMOTIONS":
      setOptions = NEGATIVE_EMOTIONS;
      break;
    default:
      setOptions = null;
      break;
  }

  switch (type) {
    case "THOUGHT_DISTORTIONS_FIELD":
      setOptions = THOUGHT_DISTORTIONS;
      break;
    case "NEGATIVE_EMOTIONS_FIELD":
      setOptions = NEGATIVE_EMOTIONS;
      break;
    default:
      setOptions = null;
      break;
  }

  const currentOptions =
    setOptions ||
    (() => {
      try {
        return JSON.parse(options);
      } catch {
        return [];
      }
    })() ||
    [];

  const currentChoice = currentOptions?.find(
    (option) => option.value === defaultValue
  );

  const ThoughtDistortionOption = (props) => {
    const { data } = props;
    return (
      <div className="thought-distortion-option">
        <components.Option {...props}>
          <div className="thought-distortion-option__label">{data.label}</div>
          <div className="thought-distortion-option__explanation">
            {data.explanation}
          </div>
          <div className="thought-distortion-option__example">
            <div className="thought-distortion-option__example--icon">
              <Icon src={QuoteLeft} alt="quote left" />
            </div>
            {data.example}
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <Select
      className="select-field"
      defaultValue={currentChoice}
      forwardControl={forwardControl}
      options={currentOptions}
      components={
        isThoughtDistortions ? { Option: ThoughtDistortionOption } : null
      }
      menuPlacement={isThoughtDistortions ? "top" : "auto"}
      {...props}
    />
  );
};

export default SelectField;
