import "react-datepicker/dist/react-datepicker.css";
import "./MultiValueField.styles.scss";
import React from "react";
import PropTypes from "prop-types";
import StringField from "../StringField";

const MultiValueField = ({ label, onChange, value, ...props }) => {
  const config = JSON.parse(props.config ? props.config : null);
  const values = JSON.parse(value || "{}");
  const fields = [];
  if (props.config) {
    for (let i = 0; i < config.fields.length; i++) {
      const partField = config.fields[i];
      fields.push(
        <StringField
          name={props.uuid + i}
          key={props.uuid + "-" + partField.name}
          placeholder={partField.placeholder}
          readOnly={props.readOnly}
          value={values[partField.name] || ""}
          onChange={(event) => {
            values[partField.name] = event.target.value;
            onChange(JSON.stringify(values));
          }}
        />
      );
    }
  }
  return (
    <div className="multi-value-field">
      <label className="multi-value-field__label">{label}</label>
      {fields}
    </div>
  );
};

MultiValueField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

MultiValueField.defaultProps = {
  type: "date",
};

export default MultiValueField;
