import React from "react";
import { Button } from "components";
import LibraryIcon from "assets/icons/books.svg";

const AddLibraryResourceButton = ({ onClick, title }) => {
  return (
    <Button
      name="add_library_resource_button"
      value={title || "Add Resource"}
      pill
      icon={LibraryIcon}
      size="small"
      onClick={onClick}
    />
  );
};

export default AddLibraryResourceButton;
