import "./styles.scss";
import React from "react";

const NotFound = () => {
  return (
    <div className="flex align-center justify-center col not-found-page">
      <h2 className="h2-2 headline">404</h2>
      <h3 className="h3-2 subline">
        The page you&apos;re looking for could not be found.
      </h3>
    </div>
  );
};

export default NotFound;
