import "./LibraryPage.styles.scss";
import React, { useEffect, useState } from "react";
import { PageTitle, PageActionBar, SidePanel, Modal, TopNav } from "components";
import {
  useCurrentUser,
  useGetPublicLibrary,
  useViewport,
  useGetTherapistLibrary,
  useFetchLibrary,
  useGetPatientLibrary,
} from "utils/hooks";
import Resource from "./components/Resource";
import File from "pages/AppPages/TherapistPages/Resources/components/Files/components/File";
import AddLibraryResourceButton from "./components/AddLibraryResourceButton";
import AddLibraryResourceForm from "./components/AddLibraryResourceForm";
import DeleteResourceConfirmationModal from "./components/DeleteResourceConfirmationModal";
import LibraryEmptyState from "./components/LibraryEmptyState";
import { useTranslation } from "react-i18next";

const LibraryPage = () => {
  const { t } = useTranslation(["common"]);
  const [showResourceForm, setShowResourceForm] = useState(false);
  const [currentResource, setCurrentResource] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { publicResources } = useGetPublicLibrary();
  const { therapistResources } = useGetTherapistLibrary({});
  const { patientResources, patientFiles } = useGetPatientLibrary();
  const { fetchTherapistLibrary, fetchPatientLibrary, fetchPublicLibrary } =
    useFetchLibrary();
  const { isDesktop } = useViewport();
  const { isTherapist, user } = useCurrentUser();
  const title = isTherapist
    ? t("patient_nav_items.resources")
    : t("patient_library_page.your_library_label");

  const sortedTherapistResources = therapistResources
    ?.slice()
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  const combinedPatientItems = [
    ...patientResources.map((resource) => ({ ...resource, type: "resource" })),
    ...patientFiles.map((file) => ({ ...file, type: "file" })),
  ].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  const allResources = [...sortedTherapistResources, ...combinedPatientItems];

  const handleEditResource = (resource) => {
    setCurrentResource(resource);
    setShowResourceForm(!showResourceForm);
  };

  const handleOpenDeleteModal = (resource) => {
    setCurrentResource(resource);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setCurrentResource(null);
    setIsDeleteModalOpen(false);
  };

  const handleCloseSidePanel = () => {
    setShowResourceForm(false);
    setCurrentResource(null);
  };

  useEffect(() => {
    fetchTherapistLibrary();
    fetchPatientLibrary();
    fetchPublicLibrary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopNav topL={<PageTitle text={title} />} />
      <div className="library-page">
        {isTherapist && isDesktop && (
          <PageActionBar>
            <div className="library-page-header">
              {isTherapist && (
                <AddLibraryResourceButton
                  onClick={() => {
                    setShowResourceForm(!showResourceForm);
                  }}
                />
              )}
            </div>
          </PageActionBar>
        )}

        <div className="library-page-body">
          <div className="library-page-section">
            {allResources?.length === 0 ? (
              <LibraryEmptyState />
            ) : (
              allResources?.map((item) => {
                if (item.type === "resource") {
                  return (
                    <Resource
                      resource={item}
                      key={item.uuid}
                      isOwner={item.addedBy === user?.id}
                      onEditResource={handleEditResource}
                      onOpenDeleteModal={handleOpenDeleteModal}
                    />
                  );
                }
                if (item.type === "file") {
                  return (
                    <File
                      file={item}
                      key={item.uuid}
                      isOwner={item.addedBy === user?.id}
                      handleFileEditClick={() => handleEditResource(item)}
                      handleFileDeleteClick={() => handleOpenDeleteModal(item)}
                    />
                  );
                }
                return null;
              })
            )}
          </div>
          <hr />
          {publicResources?.length > 0 ? (
            <>
              <h4>{t("patient_library_page.from_reflective_label")}</h4>
              <div className="library-page-section">
                {publicResources?.map((resource) => {
                  return (
                    <Resource
                      resource={resource}
                      key={resource.uuid}
                      isOwner={false}
                    />
                  );
                })}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {isTherapist && (
        <SidePanel
          title={currentResource ? "Edit Resource" : "Add Resource"}
          isVisible={showResourceForm}
          onClose={handleCloseSidePanel}
        >
          <AddLibraryResourceForm
            onSuccess={handleCloseSidePanel}
            resource={currentResource}
          />
        </SidePanel>
      )}
      {isTherapist && (
        <Modal isOpen={isDeleteModalOpen} onClose={handleCloseDeleteModal}>
          <DeleteResourceConfirmationModal
            resourceId={currentResource?.uuid}
            onCancel={handleCloseDeleteModal}
          />
        </Modal>
      )}
    </>
  );
};

export default LibraryPage;
