import "react-datepicker/dist/react-datepicker.css";
import "./InputListField.styles.scss";
import React from "react";
import PropTypes from "prop-types";
import StringField from "../StringField";

const InputListField = ({ label, onChange, value, ...props }) => {
  const config = JSON.parse(props.config ? props.config : null);
  const values = JSON.parse(value || "[]");
  const fields = [];
  if (props.config) {
    for (let i = 0; i < config.length; i++) {
      let placeholder;
      if (config.placeholderPattern === "{{index}}.") {
        placeholder = (config.placeholderPattern || "").replace(
          /{{index}}/g,
          String(i + 1)
        );
      } else {
        placeholder = config.placeholderPattern[i];
      }
      const hint = config.hintPattern ? config.hintPattern[i] : null;
      fields.push(
        <StringField
          name={props.uuid + i}
          key={props.uuid + i}
          placeholder={placeholder}
          readOnly={props.readOnly}
          value={values[i] || ""}
          onChange={(event) => {
            values[i] = event.target.value;
            onChange(JSON.stringify(values));
          }}
          hint={hint}
        />
      );
    }
  }
  return (
    <div className="input-list-field">
      <label className="input-list-field__label">{label}</label>
      {fields}
    </div>
  );
};

InputListField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

InputListField.defaultProps = {
  type: "input-list",
};

export default InputListField;
