import "./FileAttachmentFieldForDownload.styles.scss";
import React from "react";
import FileCardCondensed from "pages/AppPages/TherapistPages/AssignmentBuilder/components/FieldOptionsForm/FileChooser/FileCardCondensed";
import { useGetFileAttachment } from "utils/hooks";

interface FileAttachmentFieldProps {
  uuid: string;
  fileId: string;
}

const FileAttachmentFieldForDownload = ({
  uuid,
  fileId,
}: FileAttachmentFieldProps) => {
  const { file } = useGetFileAttachment({ fileId });

  return (
    <div key={uuid} className="file-attachment-field">
      {file ? (
        <FileCardCondensed file={file} downloadOnClick={true} />
      ) : (
        <div>
          <p>
            <small>
              <i>Loading file...</i>
            </small>
          </p>
        </div>
      )}
    </div>
  );
};

export default FileAttachmentFieldForDownload;
