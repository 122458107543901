import React, { useContext, useState } from "react";
import { ImageDisplay, ImageUploader } from "components";
import { AssignmentBuilderContext } from "pages/AppPages/TherapistPages/AssignmentBuilder/useAssignmentBuilderContext";
import { Field } from "types";
import "./ImageField.styles.scss";

interface ImageFieldProps {
  uuid: string;
  label: string;
  fileUrl: string;
  showLabelForBuilder?: boolean;
  field: Field;
  validationError?: string;
}

const ImageField = ({
  uuid,
  label,
  fileUrl,
  showLabelForBuilder,
  field,
  validationError,
}: ImageFieldProps) => {
  const { editField } = useContext(AssignmentBuilderContext);

  const [currentFileUrl, setCurrentFileUrl] = useState<string | undefined>(
    fileUrl
  );

  return (
    <div key={uuid} className="image-field">
      {showLabelForBuilder && <p className="rich-text-label">{label}</p>}
      {!currentFileUrl ? (
        <ImageUploader
          onSuccess={({ uuid, fileUrl }) => {
            setCurrentFileUrl(fileUrl);
            editField({
              ...field,
              fileId: uuid,
              fileUrl,
            });
          }}
          validationError={validationError}
        />
      ) : (
        <ImageDisplay image={currentFileUrl} />
      )}
    </div>
  );
};

export default ImageField;
