import "./OnboardingLayout.styles.scss";
import React, { useEffect } from "react";
import { useGuestRedirect } from "utils/hooks";

const AppLayout = (props) => {
  const { redirect } = useGuestRedirect();
  useEffect(() => {
    redirect();
  }, [redirect]);

  return (
    <div className="flex">
      <div className="flex col onboarding-body ">{props.children}</div>
    </div>
  );
};

export default AppLayout;
