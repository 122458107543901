import React from "react";

type Props = {
  value: string;
};
const RichTextDisplayField = ({ value }: Props) => {
  return (
    <>
      <div dangerouslySetInnerHTML={{ __html: value }}></div>
    </>
  );
};

export default RichTextDisplayField;
