import React from "react";
import PropTypes from "prop-types";
import SVG from "react-inlinesvg";
import iconMap from "./iconMap";

const Icon = ({ src }) => {
  const iconSrc = !src?.includes("/static/media/") ? iconMap[src] : src;
  return <SVG src={iconSrc} cacheRequests />;
};

Icon.propTypes = {
  src: PropTypes.string,
};

export default Icon;
