import React from "react";
import { Icon } from "components";

const MoodIcon = ({ mood }) => {
  return (
    <div className="mood-scale-field__icon">
      <Icon src={mood.icon} alt={mood.label} />
    </div>
  );
};

export default MoodIcon;
