import "react-datepicker/dist/react-datepicker.css";
import "./TimeField.styles.scss";
import React, { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";

const TimeField = ({ label, error, forwardRef, hint, ...props }) => {
  const [selectedTime, setSelectedTime] = useState(new Date());
  return (
    <div className="time-field">
      <label className="time-field__label">{label}</label>
      <DatePicker
        aria-label={label}
        className={`time-field__field ${error ? "error" : ""}`}
        ref={forwardRef}
        selected={selectedTime}
        onChange={(date) => setSelectedTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        {...props}
      />
      {error && (
        <p className="time-field__error">
          <small>{error}</small>
        </p>
      )}
      {!error && hint && (
        <p className="time-field__hint">
          <small>{hint}</small>
        </p>
      )}
    </div>
  );
};

TimeField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
};

TimeField.defaultProps = {
  type: "time",
};

export default TimeField;
